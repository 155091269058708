
<div>
    <div class="chatbot-open" v-show="open && valuador_hide">
        <div :class="`chatbot-header ${chatbot_home ? 'chatbot-header-home' : ''}`">
            <i v-if="!chatbot_home" class="fa-regular fa-arrow-circle-left fa-2xl" style="color: #fff;"
               @click="showInstanceContent(instance_values.back_instance.value)"></i>
            <div class="avatar-container">
                <img :src="avatar" alt="avatar" width="55px" height="55px" class="mx-auto"/>
            </div>
            <i class="fa-regular fa-circle-xmark fa-2xl" style="color: #ffffff;"
               @click="openCloseChat()"></i>
        </div>
        <div :class="`chatbot-container ${!chatbot_footer ? 'chatbot-container-no-footer' : ''}`">
            <div v-if="instance_values.title" class="chatbot-titles chatbot-title"
                 v-html="instance_values.title.value"></div>
            <div v-if="instance_values.subtitle_1" class="chatbot-titles chatbot-subtitle-1"
                 v-html="instance_values.subtitle_1.value"></div>
            <div v-if="instance_values.subtitle_2" class="chatbot-titles chatbot-subtitle-2"
                 v-html="instance_values.subtitle_2.value"></div>
            <div class="chatbot-buttons">
                <div v-for="(item, index) in instance_values.items.value">
                    <template
                        v-if="instance_values.items_type.value === 'button' && !(item.cta_url.startsWith('tel') && isDesktop)">
                        <template v-if="item.title !== null || item.title !== ''">
                            <div v-html="item.title"></div>
                        </template>
                        <a :href="item.cta_url" class="btn btn-primary btn-blue w-100 mx-auto my-2 chatbot-button"
                           v-html="item.cta_text"></a>
                    </template>
                    <div v-if="instance_values.items_type.value === 'p'">
                        <div v-if="index !== 0" class="chatbot-list-divider"></div>
                        <div class="chatbot-list-item">
                            <div><p class="chatbot-list-number">{{ index + 1 }}</p></div>
                            <div v-html="item.title"></div>
                        </div>
                        <div v-if="item.cta_url !== null" class="chatbot-list-cta">
                            <a :href="item.cta_url" class="btn btn-primary btn-orange w-75 mx-auto my-0"
                               v-html="item.cta_text"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="chatbot_footer" v-show="!hide_chatbot_footer"
             :class="['chatbot-footer', chatbotFooterClass(instance_values.cta_footer.value.footer_type)]"
             @click="chatbotFooterCTA(instance_values.cta_footer.value.footer_type, instance_values.cta_footer.value.next_instance)">
            <i v-if="instance_values.cta_footer.value.footer_type === 'info'"
               class="fa-regular fa-info-circle fa-xl" style="color: var(--prestaprenda-blue);"></i>
            <span v-if="instance_values.cta_footer.value.footer_type === 'valuador'">
                {{ (active_conversation) ? "Regresar a conversación" : instance_values.cta_footer.value.cta_text }}
            </span>
            <span v-else>
                {{ instance_values.cta_footer.value.cta_text }}
            </span>
        </div>
    </div>
    <div class="chatbot-closed" v-show="!open && valuador_hide" @click="openCloseChat()">
        <div class="icon-container">
            <div>
                <div class="icon-content">
                    <span>¡Hola!</span>
                </div>
                <div class="caret"></div>
            </div>
            <img :src="avatar" alt="avatar" width="36.14475px" height="34.7219664px"/>
        </div>
        <div class="text-center">
            <span style="font-size: 15px; font-weight: bold;">Yo te ayudo</span>
        </div>
    </div>
    <div v-if="show" v-show="!valuador_hide">
        <Chatbot :avatar="avatar" :budget="budget" :openChatbot="show" :productList="productList"
                 @chatbot-valuador-close="chatbotValuadorHide()"></Chatbot>
    </div>
</div>
