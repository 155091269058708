import Vue from 'vue'
import App from "@/components/chatbot/ChatBot.vue"
import store from '../components/chatbot/store.js'

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        store: store,
        render: h => h(App, {
            props: {
                chatbot_content: chatbot_content,
            }
        })
    }).$mount('#chatbot')
})